<template>
  <div class="survey-question">
    <h2>{{ title }}</h2>

    <div v-if="showPieChart" class="survey-answers">
      <survey-pie-chart v-if="closedAnswers.length" :answers="closedAnswers"></survey-pie-chart>
      <survey-answer v-for="answer of openAnswers" :key="answer.answer_id" :answer="answer" :allAnswers="allAnswers"></survey-answer>
    </div>

    <div v-else class="survey-answers">
      <survey-answer v-for="answer of answers" :key="answer.answer_id" :answer="answer" :allAnswers="allAnswers"></survey-answer>
    </div>
  </div>
</template>

<script>
import SurveyAnswer from "@/web/components/external/survey/SurveyAnswer";
import SurveyPieChart from "@/web/components/external/survey/SurveyPieChart";
import { mapState } from "vuex";

const MODE_CLOSED = 0;
const MODE_OPEN = 2;

export default {
  components: {
    SurveyAnswer,
    SurveyPieChart
  },

  props: ["question"],

  data() {
    return {};
  },

  computed: {
    ...mapState(["eventId"]),

    title() {
      return this.question.question;
    },

    answers() {
      const closedAnswers = [...this.closedAnswers];
      const sortedClosedAnswers = closedAnswers.sort((a, b) => b.all_responses - a.all_responses);

      return [...sortedClosedAnswers, ...this.openAnswers];
    },

    closedAnswers() {
      const closedAnswers = this.question.answers.filter(answer => answer.mode === MODE_CLOSED);
      return closedAnswers.sort((a, b) => b.all_responses - a.all_responses);
    },

    openAnswers() {
      return this.question.answers.filter(answer => answer.mode === MODE_OPEN);
    },

    allAnswers() {
      return this.question.answers.reduce((acc, answer) => acc + answer.all_responses, 0);
    },

    showPieChart() {
      return this.eventId === 144;
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 18px;
  }
}
</style>
